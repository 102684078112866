import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { DealerContext } from '../../components/shared/contexts/DealerContext';
import logo from '../../components/shared/assets/OmodaAssets/omoda-logo-white.svg.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 5,
    bottom: 0,
  },
  bottomSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0px 0px',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '0px 100px',
    background: '#30363a',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 0px',
    },
    zIndex: 4,
  },
  HolderTop: {
    background: 'white',
    padding: '48px 0px',
    zIndex: 4,
    bottom: 0,
  },
  innerHolderTopItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'space-evenly',
    textAlign: 'left',
  },
  innerHolderTop: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flex: 1,
    // padding: '0px 40px',
    paddingLeft: '100px',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
    },
  },
  innerHolder: {
    width: '100%',
    margin: '0 auto',
    padding: '0px 40px',
    justifyContent: 'space-evenly',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(166,183,190,.3)',
    padding: '32px 0px',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '15px',
      flexDirection: 'column',
    },
  },
  sectionMid: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(166,183,190,.3)',
    padding: '30px 0px',
    paddingBottom: '60px',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '15px',
      flexDirection: 'column',
    },
  },
  sectionFlex: {
    display: 'flex',
    '@media (min-width:769px) and (max-width:1024px)': {
      flexDirection: 'column',
    },
  },
  sectionInner: {
    display: 'flex',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
      margin: '5px 0px',
    },
    '@media (min-width:769px) and (max-width:1024px)': {
      flexDirection: 'column',
    },
  },
  sectionInnerGroup: {
    width: 'calc(100%/2)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  GroupedSection: {
    display: 'flex',
    width: 'calc(100%/3)',
    flexDirection: 'column',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  heading: {
    fontFamily: 'OmodaR',
    color: '#6b777c',
    fontSize: '24px',
  },
  headingText: {
    fontFamily: 'OmodaR',
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
  },
  headingTextFlex: {
    fontFamily: 'OmodaR',
    display: 'flex',
    color: 'white',
  },
  imgSection: {
    width: 'calc(100%/3)',
    textAlign: 'center',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  hr: {
    border: '1px #6c757d70 solid',
    width: '100%',
  },
  legal: {
    fontFamily: 'OmodaR',
    fontSize: '8pt',
  },
  socialLinkHolder: {
    display: 'flex',
    '@media (min-width:0px) and (max-width:768px)': {
      margin: '5px 0px',
    },
  },
  socialLink: {
    padding: '0px 5px',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: 'calc(100%/6)!important',
    },
  },
  socialLinkIcon: {
    width: '30px!important',
    height: '30px!important',
  },
  img: {
    display: 'flex',
    height: '100%',
    alignItems: 'end',
    '@media (max-width: 768px)': {
      width: '100%',
      margin: '0px 10px',
    },
    '@media (min-width: 769px) and (max-width: 1200px)': {
      width: '60%',
    },
  },
  logo: {
    width: '150px',
    height: '50px',
    objectFit: 'contain',
    '@media (max-width: 768px)': {
      width: '150px',
    },
    '@media (min-width: 769px) and (max-width: 1200px)': {
      width: '200px',
    },
  },
  sectionLink: {
    fontFamily: 'OmodaR',
    margin: '0px 10px',
    color: 'white',
    fontSize: '14px',
  },
  innerHolderTopItemLink: {
    fontFamily: 'OmodaL',
    fontWeight: 500,
    margin: '0px 0px',
    color: '#0c0c0b',
    fontSize: '16px',
    flexWrap: 'wrap',
  },
  sectionLinkB: {
    fontFamily: 'OmodaR',
    margin: '0px 10px',
    color: 'white',
    fontSize: '14px',
  },
  sectionLinkBL: {
    fontFamily: 'OmodaR',
    margin: '0px 10px',
    color: '#a6b7be',
    fontSize: '14px',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { dealerSocials, globalDealer } = useContext(DealerContext);

  return (
    <div className={classes.root}>
      <div className={classes.HolderTop}>
        <div className={classes.innerHolderTop}>
          <div className={classes.innerHolderTopItem}>
            <Link to="/book-a-testdrive" className={classes.innerHolderTopItemLink}>
              Book a Test Drive
            </Link>
          </div>

          <div className={classes.innerHolderTopItem}>
            <Link to="/showroom" className={classes.innerHolderTopItemLink}>
              View Models
            </Link>
          </div>

          <div className={classes.innerHolderTopItem}>
            <Link to="/book-a-service" className={classes.innerHolderTopItemLink}>
              Book a service
            </Link>
          </div>

          <div className={classes.innerHolderTopItem}>
            <Link to="/contact-us" className={classes.innerHolderTopItemLink}>
              Contact
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.bottomSection}>
        <div className={classes.innerHolder}>
          <div className={classes.section}>
            <div className={classes.img}>
              <Link to="/">
                <img alt="" src={logo} width="100%" className={classes.logo} />
              </Link>
            </div>
            <div>
              <div className={classes.headingTextFlex}>
                <p className={classes.sectionLinkBL}>Contact Us:</p>{' '}
                {globalDealer?.newCarsPhoneNumber}
              </div>
            </div>
          </div>
          <div className={classes.sectionMid}>
            <div className={classes.sectionInner}>
              <Link to="/showroom" className={classes.sectionLink}>
                Models
              </Link>
              <Link to="/promotions" className={classes.sectionLink}>
                Promotions
              </Link>
              <Link to="/brand/o-universe" className={classes.sectionLink}>
                O-Universe
              </Link>
              <Link to="/book-a-service" className={classes.sectionLink}>
                Service
              </Link>
              <Link to="/parts" className={classes.sectionLink}>
                Parts
              </Link>
              <Link to="/book-a-testdrive" className={classes.sectionLink}>
                Book a Test Drive
              </Link>
              <Link to="/contact-us" className={classes.sectionLink}>
                Contact
              </Link>
              <Link to="/apply-for-finance" className={classes.sectionLink}>
                Finance
              </Link>
            </div>
            <div className={classes.socialLinkHolder}>
              {dealerSocials?.facebook && (
                <div className={classes.socialLink}>
                  <a
                    href={dealerSocials.facebook}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <FacebookIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}
              {dealerSocials?.instagram && (
                <div className={classes.socialLink}>
                  <a
                    href={dealerSocials.instagram}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <InstagramIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}
              {dealerSocials?.twitter && (
                <div className={classes.socialLink}>
                  <a
                    href={dealerSocials.twitter}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <TwitterIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}
              {dealerSocials?.linkedIn && (
                <div className={classes.socialLink}>
                  <a
                    href={dealerSocials.linkedIn}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <LinkedInIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className={classes.sectionMid}>
            <div className={classes.sectionInner}>
              <p className={classes.sectionLinkBL}>
                © {new Date().getFullYear().toString()} Mekor OMODA
              </p>
              <Link to="/legal-information" className={classes.sectionLinkB}>
                Legal Information
              </Link>
            </div>
            <div className={classes.sectionInner}>
              <Link to="/terms-conditions" className={classes.sectionLinkB}>
                Terms & Conditions
              </Link>
              <Link to="/privacy-policy" className={classes.sectionLinkB}>
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
