import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DealsFilter from '../../components/SpecialDeals/DealsFilter';
import heroBanner from '../../components/shared/assets/OmodaAssets/hero-banner.webp';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '100%',
    padding: '0px 0px',
  },
  background: {
    width: '100vw',
    padding: '40px 15px',
    '@media (max-width: 425px)': {
      padding: '10px',
    },
    '@media (min-width: 426px) and (max-width: 1200px)': {
      maxWidth: '20px 5px',
    },
  },
  title: {
    fontSize: '2rem',
    color: 'white',
    textTransform: 'uppercase',
  },
  cardHolder: {
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '20px',
    width: '100%',
    margin: '10px',
    minWidth: 'calc(100% / 3 - 20px)',
    maxWidth: 'calc(100% / 3 - 20px)',
    '@media (max-width: 425px)': {
      maxWidth: '100%',
      padding: '10px',
      margin: '10px',
    },
    '@media (min-width: 426px) and (max-width: 1200px)': {
      maxWidth: 'calc(100% / 2 - 20px)',
    },
  },
  cardTextContainer: {
    width: '100%',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    width: '100%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subText: {
    fontFamily: 'OmodaL',
    fontWeight: 100,
    textAlign: 'center',
  },
  installmentText: {
    fontFamily: 'OmodaR',
    fontSize: '25px',
    fontWeight: '600',
  },
  headlineText: {
    fontFamily: 'OmodaR',
    fontSize: '20px',
    marginBottom: '0px !important',
    textTransform: 'uppercase',
  },
  infoText: {
    fontFamily: 'OmodaL',
    fontWeight: 100,
  },
  labelText: {
    fontFamily: 'OmodaL',
    fontWeight: 100,
    fontSize: '15px',
  },
  fromText: {
    fontFamily: 'OmodaR',
    fontSize: '16px',
    marginBottom: '0px !important',
    padding: 0,
  },
  button: {
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    width: '100%',
    height: '70px',
    margin: '20px 0px 0px 0px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  h1: {
    fontFamily: 'OmodaR',
    fontWeight: 500,
    fontSize: '50px',
    textAlign: 'center',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },
}));

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export default function SpecialDeals() {
  const classes = useStyles();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [sortDirection, setSortDirection] = useState('Desc');
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const [sortDeals, setSortDeals] = useState([]);
  const { deals } = DealsFilter(pageNumber, sortDirection);
  const handlePageChange = (deal) => {
    history.push(`/special-deals/${deal?.offerId}`, deal);
  };

  // const renderInfo = (label, value, width) => (
  //   <div
  //     style={{
  //       display: 'flex',
  //       flexDirection: 'column',
  //       width,
  //       borderBottom: '1px solid',
  //       // margin: '5px 10px',
  //     }}
  //   >
  //     <p className={classes.labelText}>{label}</p>
  //     <p className={classes.infoText}>{value}</p>
  //   </div>
  // );
  useEffect(() => {
    setSortDeals(deals.sort((a, b) => a.priority - b.priority));
  }, [deals]);

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 0,
  });

  return (
    <div className={classes.root}>
      {/* <div style={{ display: 'flex' }}>
                  <p className={classes.headlineText}>{deal.headline}</p>
                  <p className={classes.installmentText}>R {deal.monthlyInstallment}*PM</p>
                </div>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  {renderInfo('Retail Price:', `R ${deal.price}`, 'calc(100% / 2 - 20px)')}
                  {renderInfo(
                    `${deal.fixedRate ? 'Fixed' : 'Linked'} Interest:`,
                    `${deal.rate} %`,
                    'calc(100% / 2 - 20px)'
                  )}
                </div>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  {renderInfo(
                    'Deposit:',
                    `${deal.depositCash ? 'R' : ''} 
                    ${deal.deposit} 
                    ${deal.depositCash ? '' : '%'}`,
                    'calc(100% / 3 - 20px)'
                  )}
                  {renderInfo('Period:', `${deal.term} months`, 'calc(100% / 3 - 20px)')}
                  {renderInfo(
                    'Balloon:',
                    `${deal.balloonPaymentCash ? 'R' : ''} 
                    ${deal.balloonPayment} 
                    ${deal.balloonPaymentCash ? '' : '%'}`,
                    'calc(100% / 3 - 20px)'
                  )}
                </div>
                <p className={classes.subText}>
                  A once-off bank Initiation Fee is charged. The total cost of finance including the
                  banking fees and balloon payment is R {deal.totalCost} .
                </p> */}
      <img alt="" src={heroBanner} style={{ width: '100%', objectFit: 'cover' }} />
      <h1 className={classes.h1}>Omoda Promotions</h1>
      <div className={classes.background}>
        <div className={classes.cardHolder}>
          {sortDeals.length > 0 ? (
            sortDeals.map((deal, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={classes.card}>
                <img alt="" src={deal?.imageUrl} width="100%" style={{ aspectRatio: 1 / 1 }} />
                {deal?.isCashDeal ? (
                  <div className={classes.detailsContainer}>
                    {/* is cash deal */}
                    <p className={classes.headlineText}>{deal.headline}</p>
                    <p className={classes.installmentText}>
                      <span className={classes.fromText}>For only </span>
                      {formatter.format(` ${deal.price}`)}
                    </p>
                    <button
                      className={classes.button}
                      type="button"
                      onClick={() => {
                        handlePageChange(deal);
                      }}
                    >
                      <p style={{ fontSize: '20px', fontFamily: 'OmodaL', fontWeight: 100 }}>
                        View Offer
                      </p>
                    </button>
                  </div>
                ) : (
                  <div className={classes.detailsContainer}>
                    {/* is finance deal */}
                    <p className={classes.headlineText}>{deal.headline}</p>
                    <p className={classes.installmentText}>
                      <span className={classes.fromText}>From </span>
                      {formatter.format(` ${deal.monthlyInstallment} `)}
                      <span className={classes.fromText}> *PM</span>
                    </p>
                    <button
                      className={classes.button}
                      type="button"
                      onClick={() => {
                        handlePageChange(deal);
                      }}
                    >
                      <p style={{ fontSize: '20px', fontFamily: 'OmodaL', fontWeight: 100 }}>
                        View Offer
                      </p>
                    </button>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className={classes.textContainer}>
              <Alert severity="info">There are currently no available offers.</Alert>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
